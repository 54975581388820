import axios from 'axios'
import { Features } from 'components'
import { Button2 } from 'components/buttons'
import { useUser } from 'contexts'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { offers } from 'utils/datas/offers'
import { displayPrice } from 'utils/transformers'

type LockPageProps = {
  element: ReactNode
}

const LockPage = ({ element }: LockPageProps) => {
  const { t } = useTranslation()
  const { activeCompany } = useUser()

  // const goToManage = async () => {
  //   const res = await axios.put(`company/${activeCompany?.id}/plan/manage`)
  //   window.location.href = res.data.data.stripeSession
  // }

  const goToCancel = async () => {
    const res = await axios.delete(`company/${activeCompany?.id}/plan/cancel`)
    window.location.href = res.data.data.stripeSession
  }

  const goToUpgrade = async () => {
    const res = await axios.post(`company/${activeCompany?.id}/plan/upgrade`)
    window.location.href = res.data.data.stripeSession
  }

  return <div>
    {element}
    <div className='flex flex-col gap-8 absolute top-0 left-0 w-full h-full items-center justify-center px-5 backdrop-blur-md'>
      <div className='flex gap-4 w-full justify-center'>
        {!offers.length
          ? <div className="border-t-transparent border-solid animate-spin rounded-full border-primary border-8 h-48 w-48" />
          : offers.map(offer => <div key={`offer-card-${offer.id}`} className={twMerge('bg-white p-4 pt-6 relative flex flex-col w-1/3 items-center justify-between gap-4 rounded-md shadow-md border', offer.id === activeCompany?.plan ? 'shadow-primary border-primary' : 'shadow-gray-300 border-gray-300')}>
              {offer.favorite
                ? <div className='absolute right-2 top-2 text-xs bg-primary text-white rounded-full px-2 py-1'>{t('offers:favorite')}</div>
                : null
              }
              <div className='flex flex-col items-center'>
                <div className='text-xl font-semibold'>
                  {t(`offers:name.${offer.id}`)}
                </div>
                <div className='text-base flex flex-col items-center'>
                  {
                    offer.price === 0
                      ? <span className='mb-2.5'>{t('offers:free')}</span>
                      : offer.price === -1
                        ? <span className='mb-2.5'>{t('offers:ondemand')}</span>
                        : <>
                            <div>{displayPrice(offer.price)} <span className="text-xs">{t('offers:quantity')}</span></div>
                            <span className='text-2xs -mt-2'>{t('offers:vat')}</span>
                          </>
                  }
                </div>
                <Features offer={offer} />
              </div>
              {offer.id === activeCompany?.plan
                ? <div className='flex flex-col items-center'>
                    <span className='text-lg font-medium text-primary'>{t('offers:actions.current')}</span>
                  </div>
                : offer.id === 'BASIC'
                  ? <Button2 outlined onClick={goToCancel}>{t('offers:actions.downgrade')!}</Button2>
                  : offer.id === 'ENTERPRISE'
                    ? <a href={process.env.REACT_APP_CLOKIZI_RESERVATION_LINK} target='_blank' rel="noreferrer"><Button2 outlined onClick={null}>{t('offers:actions.contactus')!}</Button2></a>
                    : offer.id === 'PRO' && activeCompany?.plan === 'BASIC'
                      ? <Button2 onClick={goToUpgrade}>{t('offers:actions.upgrade')!}</Button2>
                      : <Button2 outlined onClick={goToCancel}>{t('offers:actions.downgrade')!}</Button2>
                // : <Button className='mt-4' onClick={() => } outlined={planTypeValue[offer.type] < planTypeValue[selectedWorkspace!.plan.type]}>
                //     {planTypeValue[offer.type] < planTypeValue[selectedWorkspace!.plan.type] ? t('offers:actions.downgrade')! : t('offers:actions.upgrade')!}
                //   </Button>
              }
            </div>
          )
        }
      </div>
    </div>
  </div>
}

export default LockPage
