import { Trans, useTranslation } from 'react-i18next'

import { twMerge } from 'tailwind-merge'

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Offer } from 'types'

type FeaturesProps = {
  offer: Offer
  className?: string
}

const Features = ({ offer, className }: FeaturesProps) => {
  const { t } = useTranslation()

  const Soon = () => <span className='rounded-lg bg-blue-300 text-xs text-white px-1.5 font-medium'>{t('offers:soon')}</span>

  return <ul className={twMerge('my-4 flex flex-col gap-1.5 text-sm', className)}>
    <li className='flex items-center font-light text-sm gap-1'><Trans i18nKey="offers:features.agents" values={{ quantity: offer.agents }} components={{ custom: <span className='text-primary font-medium' /> }} /></li>
    <li className='flex items-center font-light text-sm gap-1'><Trans i18nKey="offers:features.admin" values={{ quantity: offer.admin }} components={{ custom: <span className='text-primary font-medium' /> }} /></li>
    <li className='flex items-center font-light text-sm gap-1'><Trans i18nKey="offers:features.manager" values={{ quantity: offer.manager }} components={{ custom: <span className='text-primary font-medium' /> }} /></li>
    <li className='flex items-center gap-1'><span className={twMerge('font-light text-sm', offer.features.dashboard ? '' : 'line-through')}>{t('offers:features.dashboard')}</span><span data-tooltip-id="tooltip" data-tooltip-content={t('offers:features.dashboard-info')!} data-tooltip-place='bottom'><InformationCircleIcon className='h-4 w-4' /></span></li>
    <li className='flex items-center gap-1'><span className={twMerge('font-light text-sm', offer.features.dashboard ? '' : 'line-through')}>{t('offers:features.geolocation')}</span><span data-tooltip-id="tooltip" data-tooltip-content={t('offers:features.geolocation-info')!} data-tooltip-place='bottom'><InformationCircleIcon className='h-4 w-4' /></span></li>
    <li className='flex items-center gap-1'><span className={twMerge('font-light text-sm', offer.features.dashboard ? '' : 'line-through')}>{t('offers:features.signature')}</span><Soon /><span data-tooltip-id="tooltip" data-tooltip-content={t('offers:features.signature-info')!} data-tooltip-place='bottom'><InformationCircleIcon className='h-4 w-4' /></span></li>
    <li className='flex items-center gap-1'><span className={twMerge('font-light text-sm', offer.features.recurring ? '' : 'line-through')}>{t('offers:features.recurring')}</span><Soon /><span data-tooltip-id="tooltip" data-tooltip-content={t('offers:features.recurring-info')!} data-tooltip-place='bottom'><InformationCircleIcon className='h-4 w-4' /></span></li>
    <li className='flex items-center gap-1'><span className={twMerge('font-light text-sm', offer.features.invoicing ? '' : 'line-through')}>{t('offers:features.invoicing')}</span><Soon /><span data-tooltip-id="tooltip" data-tooltip-content={t('offers:features.invoicing-info')!} data-tooltip-place='bottom'><InformationCircleIcon className='h-4 w-4' /></span></li>
  </ul>
}

export default Features
