const getCoord = async (placeId: string, mcallback: (lng: number, lat: number) => void) => {
  // https://developers.google.com/maps/documentation/javascript/reference/places-service?hl=fr#PlaceDetailsRequest
  const service = new window.google.maps.places.PlacesService(document.createElement('div'))
  const request = {
    placeId
  }

  service.getDetails(request, (res, status) => {
    mcallback(res?.geometry?.location?.lng() || 0, res?.geometry?.location?.lat() || 0)
  })
}

const getDetails = async (placeId: string, mcallback: (res: any) => void) => {
  // https://developers.google.com/maps/documentation/javascript/reference/places-service?hl=fr#PlaceDetailsRequest
  const service = new window.google.maps.places.PlacesService(document.createElement('div'))
  const request = {
    placeId
  }

  service.getDetails(request, (res, status) => {
    mcallback(res)
  })
}

const getAutocompleteAddress = async (input: string, options?: {
  personal?: boolean
  language?: string
  country?: string
}) => {
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service?hl=fr
  if (!input.length) return []

  const service = new window.google.maps.places.AutocompleteService()
  const request = {
    input,
    types: options?.personal ? ['address'] : undefined,
    language: options?.language || 'en'
  }

  const predictions = (await service.getPlacePredictions(request)).predictions

  return predictions
}

export { getDetails, getAutocompleteAddress, getCoord }
