import { twMerge } from 'tailwind-merge'

type AvatarProps = {
  src?: string
  alt?: string
  className?: string
  activeTooltip?: boolean
}

const Avatar = ({ src, alt, className = '', activeTooltip }: AvatarProps) => {
  return src
    ? <img
        className={twMerge('w-8 rounded-full', className, 'h-auto object-cover aspect-square')}
        src={src}
        loading="lazy"
        alt={alt}
        data-tooltip-id={activeTooltip ? 'tooltip' : ''}
        data-tooltip-content={alt}
        data-tooltip-place='bottom'
      />
    : <div
        className={twMerge('w-8 rounded-full text-xs bg-slate-300 text-black flex items-center justify-center', className, 'object-cover aspect-square')}
        data-tooltip-id={activeTooltip ? 'tooltip' : ''}
        data-tooltip-content={alt}
        data-tooltip-place='bottom'
      >
        {alt?.length ? alt[0].toUpperCase() : 'C'}
      </div>
}

export default Avatar
