// generic
import global from './global_en.json'
import datas from './datas_en.json'
import menu from './menu_en.json'
import offers from './offers_en.json'
import inputs from './inputs_en.json'
// pages auth
import login from './login_en.json'
import register from './register_en.json'
import reset from './reset_en.json'
import forgot from './forgot_en.json'
import welcome from './welcome_en.json'
import onboarding from './onboarding_en.json'
import agent from './agent_en.json'
// pages app
import dashboard from './dashboard_en.json'
import interventions from './interventions_en.json'
import intervention from './intervention_en.json'
import settings from './settings_en.json'
import users from './users_en.json'
import customers from './customers_en.json'
import profile from './profile_en.json'

export default {
  // generic
  global,
  datas,
  offers,
  menu,
  inputs,
  // pages auth
  login,
  register,
  reset,
  welcome,
  forgot,
  onboarding,
  agent,
  // pages app
  dashboard,
  interventions,
  intervention,
  settings,
  users,
  customers,
  profile
}
