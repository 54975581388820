import { twMerge } from 'tailwind-merge'

type SkeletonProps = {
  className?: string
}

const Skeleton = ({ className }: SkeletonProps) => {
  return <div className={twMerge('animate-pulse bg-gray-200 rounded-md dark:bg-gray-700', className)} />
}

export default Skeleton
