import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

type ButtonProps = {
  children: ReactElement | ReactElement[] | string
  onClick: (() => void) | null
  status?: 'DEFAULT' | 'SUCCESS' | 'DANGER' | 'ERROR' | 'INFO'
  outlined?: boolean
  className?: string
  disabled?: boolean
}

const statusToClass = {
  outlined: {
    DEFAULT: 'focus:ring-primary-dark border-primary hover:border-primary-dark text-primary hover:bg-blue-50',
    SUCCESS: 'focus:ring-green-600 border-green-500 hover:border-green-600 text-green-500 hover:text-green-600 hover:bg-green-50',
    ERROR: 'focus:ring-red-600 border-red-500 hover:border-red-600 text-red-500 hover:text-red-600 hover:bg-red-50',
    DANGER: 'focus:ring-red-600 border-orange-500 hover:border-orange-600 text-orange-500 hover:text-orange-600 hover:bg-orange-50',
    INFO: 'focus:ring-blue-600 border-blue-500 hover:border-blue-600 text-blue-500 hover:text-blue-600 hover:bg-blue-50'
  },
  noutlined: {
    DEFAULT: 'focus:ring-primary-dark bg-primary hover:bg-primary-dark text-white',
    SUCCESS: 'focus:ring-green-600 bg-green-500 hover:bg-green-600 text-white',
    ERROR: 'focus:ring-red-600 bg-red-500 hover:bg-red-600 text-white',
    DANGER: 'focus:ring-red-600 bg-orange-500 hover:bg-orange-600 text-white',
    INFO: 'focus:ring-blue-600 bg-blue-500 hover:bg-blue-600 text-white'
  }
}

const Button2 = ({ children, onClick, className, outlined, status = 'DEFAULT', disabled }: ButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick || (() => {})}
      className={
        twMerge('select-none inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
          statusToClass[outlined ? 'outlined' : 'noutlined'][status],
          className
        )
      }
    >
      {children}
    </button>
  )
}

export default Button2
