import i18next from 'i18next'
import { Countries, Country, Option, PlanCurrencies } from 'types'

const countries: Country[] = [
  {
    name: 'Afghanistan',
    phoneCode: '+93',
    code: 'AF',
    flag: '🇦🇫',
    currency: 'AFN'
  },
  {
    name: 'Albania',
    phoneCode: '+355',
    code: 'AL',
    flag: '🇦🇱',
    currency: 'ALL'
  },
  {
    name: 'Algeria',
    phoneCode: '+213',
    code: 'DZ',
    flag: '🇩🇿',
    currency: 'DZD'
  },
  {
    name: 'AmericanSamoa',
    phoneCode: '+1684',
    code: 'AS',
    flag: '🇦🇸',
    currency: 'USD'
  },
  {
    name: 'Andorra',
    phoneCode: '+376',
    code: 'AD',
    flag: '🇦🇩',
    currency: 'EUR'
  },
  {
    name: 'Angola',
    phoneCode: '+244',
    code: 'AO',
    flag: '🇦🇴',
    currency: 'AOA'
  },
  {
    name: 'Anguilla',
    phoneCode: '+1264',
    code: 'AI',
    flag: '🇦🇮',
    currency: 'XCD'
  },
  {
    name: 'Antarctica',
    phoneCode: '+672',
    code: 'AQ',
    flag: '🇦🇶',
    currency: 'AQD'
  },
  {
    name: 'Antigua and Barbuda',
    phoneCode: '+1268',
    code: 'AG',
    flag: '🇦🇬',
    currency: 'XCD'
  },
  {
    name: 'Argentina',
    phoneCode: '+54',
    code: 'AR',
    flag: '🇦🇷',
    currency: 'ARS'
  },
  {
    name: 'Armenia',
    phoneCode: '+374',
    code: 'AM',
    flag: '🇦🇲',
    currency: 'AMD'
  },
  {
    name: 'Aruba',
    phoneCode: '+297',
    code: 'AW',
    flag: '🇦🇼',
    currency: 'AWG'
  },
  {
    name: 'Australia',
    phoneCode: '+61',
    code: 'AU',
    flag: '🇦🇺',
    currency: 'AUD'
  },
  {
    name: 'Austria',
    phoneCode: '+43',
    code: 'AT',
    flag: '🇦🇹',
    currency: 'EUR'
  },
  {
    name: 'Azerbaijan',
    phoneCode: '+994',
    code: 'AZ',
    flag: '🇦🇿',
    currency: 'AZN'
  },
  {
    name: 'Bahamas',
    phoneCode: '+1242',
    code: 'BS',
    flag: '🇧🇸',
    currency: 'BSD'
  },
  {
    name: 'Bahrain',
    phoneCode: '+973',
    code: 'BH',
    flag: '🇧🇭',
    currency: 'BHD'
  },
  {
    name: 'Bangladesh',
    phoneCode: '+880',
    code: 'BD',
    flag: '🇧🇩',
    currency: 'BDT'
  },
  {
    name: 'Barbados',
    phoneCode: '+1246',
    code: 'BB',
    flag: '🇧🇧',
    currency: 'BBD'
  },
  {
    name: 'Belarus',
    phoneCode: '+375',
    code: 'BY',
    flag: '🇧🇾',
    currency: 'BYR'
  },
  {
    name: 'Belgium',
    phoneCode: '+32',
    code: 'BE',
    flag: '🇧🇪',
    currency: 'EUR'
  },
  {
    name: 'Belize',
    phoneCode: '+501',
    code: 'BZ',
    flag: '🇧🇿',
    currency: 'BZD'
  },
  {
    name: 'Benin',
    phoneCode: '+229',
    code: 'BJ',
    flag: '🇧🇯',
    currency: 'XOF'
  },
  {
    name: 'Bermuda',
    phoneCode: '+1441',
    code: 'BM',
    flag: '🇧🇲',
    currency: 'BMD'
  },
  {
    name: 'Bhutan',
    phoneCode: '+975',
    code: 'BT',
    flag: '🇧🇹',
    currency: 'BTN'
  },
  {
    name: 'Bolivia, Plurinational State of',
    phoneCode: '+591',
    code: 'BO',
    flag: '🇧🇴',
    currency: 'BOB'
  },
  {
    name: 'Bosnia and Herzegovina',
    phoneCode: '+387',
    code: 'BA',
    flag: '🇧🇦',
    currency: 'BAM'
  },
  {
    name: 'Botswana',
    phoneCode: '+267',
    code: 'BW',
    flag: '🇧🇼',
    currency: 'BWP'
  },
  {
    name: 'Brazil',
    phoneCode: '+55',
    code: 'BR',
    flag: '🇧🇷',
    currency: 'BRL'
  },
  {
    name: 'British Indian Ocean Territory',
    phoneCode: '+246',
    code: 'IO',
    flag: '🇮🇴',
    currency: 'USD'
  },
  {
    name: 'Brunei Darussalam',
    phoneCode: '+673',
    code: 'BN',
    flag: '🇧🇳',
    currency: 'BND'
  },
  {
    name: 'Bulgaria',
    phoneCode: '+359',
    code: 'BG',
    flag: '🇧🇬',
    currency: 'BGN'
  },
  {
    name: 'Burkina Faso',
    phoneCode: '+226',
    code: 'BF',
    flag: '🇧🇫',
    currency: 'XOF'
  },
  {
    name: 'Burundi',
    phoneCode: '+257',
    code: 'BI',
    flag: '🇧🇮',
    currency: 'BIF'
  },
  {
    name: 'Cambodia',
    phoneCode: '+855',
    code: 'KH',
    flag: '🇰🇭',
    currency: 'KHR'
  },
  {
    name: 'Cameroon',
    phoneCode: '+237',
    code: 'CM',
    flag: '🇨🇲',
    currency: 'XAF'
  },
  {
    name: 'Canada',
    phoneCode: '+1',
    code: 'CA',
    flag: '🇨🇦',
    currency: 'CAD'
  },
  {
    name: 'Cape Verde',
    phoneCode: '+238',
    code: 'CV',
    flag: '🇨🇻',
    currency: 'CVE'
  },
  {
    name: 'Cayman Islands',
    phoneCode: '+345',
    code: 'KY',
    flag: '🇰🇾',
    currency: 'KYD'
  },
  {
    name: 'Central African Republic',
    phoneCode: '+236',
    code: 'CF',
    flag: '🇨🇫',
    currency: 'XAF'
  },
  {
    name: 'Chad',
    phoneCode: '+235',
    code: 'TD',
    flag: '🇹🇩',
    currency: 'XAF'
  },
  {
    name: 'Chile',
    phoneCode: '+56',
    code: 'CL',
    flag: '🇨🇱',
    currency: 'CLP'
  },
  {
    name: 'China',
    phoneCode: '+86',
    code: 'CN',
    flag: '🇨🇳',
    currency: 'CNY'
  },
  {
    name: 'Christmas Island',
    phoneCode: '+61',
    code: 'CX',
    flag: '🇨🇽',
    currency: 'AUD'
  },
  {
    name: 'Cocos (Keeling) Islands',
    phoneCode: '+61',
    code: 'CC',
    flag: '🇨🇨',
    currency: 'AUD'
  },
  {
    name: 'Colombia',
    phoneCode: '+57',
    code: 'CO',
    flag: '🇨🇴',
    currency: 'COP'
  },
  {
    name: 'Comoros',
    phoneCode: '+269',
    code: 'KM',
    flag: '🇰🇲',
    currency: 'KMF'
  },
  {
    name: 'Congo',
    phoneCode: '+242',
    code: 'CG',
    flag: '🇨🇬',
    currency: 'XAF'
  },
  {
    name: 'Congo, The Democratic Republic of the',
    phoneCode: '+243',
    code: 'CD',
    flag: '🇨🇩',
    currency: 'CDF'
  },
  {
    name: 'Cook Islands',
    phoneCode: '+682',
    code: 'CK',
    flag: '🇨🇰',
    currency: 'NZD'
  },
  {
    name: 'Costa Rica',
    phoneCode: '+506',
    code: 'CR',
    flag: '🇨🇷',
    currency: 'CRC'
  },
  {
    name: "Cote d'Ivoire",
    phoneCode: '+225',
    code: 'CI',
    flag: '🇨🇮',
    currency: 'XOF'
  },
  {
    name: 'Croatia',
    phoneCode: '+385',
    code: 'HR',
    flag: '🇭🇷',
    currency: 'HRK'
  },
  {
    name: 'Cuba',
    phoneCode: '+53',
    code: 'CU',
    flag: '🇨🇺',
    currency: 'CUP'
  },
  {
    name: 'Cyprus',
    phoneCode: '+357',
    code: 'CY',
    flag: '🇨🇾',
    currency: 'EUR'
  },
  {
    name: 'Czech Republic',
    phoneCode: '+420',
    code: 'CZ',
    flag: '🇨🇿',
    currency: 'CZK'
  },
  {
    name: 'Denmark',
    phoneCode: '+45',
    code: 'DK',
    flag: '🇩🇰',
    currency: 'DKK'
  },
  {
    name: 'Djibouti',
    phoneCode: '+253',
    code: 'DJ',
    flag: '🇩🇯',
    currency: 'DJF'
  },
  {
    name: 'Dominica',
    phoneCode: '+1767',
    code: 'DM',
    flag: '🇩🇲',
    currency: 'XCD'
  },
  {
    name: 'Dominican Republic',
    phoneCode: '+1849',
    code: 'DO',
    flag: '🇩🇴',
    currency: 'DOP'
  },
  {
    name: 'Ecuador',
    phoneCode: '+593',
    code: 'EC',
    flag: '🇪🇨',
    currency: 'USD'
  },
  {
    name: 'Egypt',
    phoneCode: '+20',
    code: 'EG',
    flag: '🇪🇬',
    currency: 'EGP'
  },
  {
    name: 'El Salvador',
    phoneCode: '+503',
    code: 'SV',
    flag: '🇸🇻',
    currency: 'USD'
  },
  {
    name: 'Equatorial Guinea',
    phoneCode: '+240',
    code: 'GQ',
    flag: '🇬🇶',
    currency: 'XAF'
  },
  {
    name: 'Eritrea',
    phoneCode: '+291',
    code: 'ER',
    flag: '🇪🇷',
    currency: 'ERN'
  },
  {
    name: 'Estonia',
    phoneCode: '+372',
    code: 'EE',
    flag: '🇪🇪',
    currency: 'EUR'
  },
  {
    name: 'Ethiopia',
    phoneCode: '+251',
    code: 'ET',
    flag: '🇪🇹',
    currency: 'ETB'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    phoneCode: '+500',
    code: 'FK',
    flag: '🇫🇰',
    currency: 'FKP'
  },
  {
    name: 'Faroe Islands',
    phoneCode: '+298',
    code: 'FO',
    flag: '🇫🇴',
    currency: 'DKK'
  },
  {
    name: 'Fiji',
    phoneCode: '+679',
    code: 'FJ',
    flag: '🇫🇯',
    currency: 'FJD'
  },
  {
    name: 'Finland',
    phoneCode: '+358',
    code: 'FI',
    flag: '🇫🇮',
    currency: 'EUR'
  },
  {
    name: 'France',
    phoneCode: '+33',
    code: 'FR',
    language: 'FR',
    flag: '🇫🇷',
    currency: 'EUR'
  },
  {
    name: 'French Guiana',
    phoneCode: '+594',
    code: 'GF',
    flag: '🇬🇫',
    currency: 'EUR'
  },
  {
    name: 'French Polynesia',
    phoneCode: '+689',
    code: 'PF',
    flag: '🇵🇫',
    currency: 'XPF'
  },
  {
    name: 'Gabon',
    phoneCode: '+241',
    code: 'GA',
    flag: '🇬🇦',
    currency: 'XAF'
  },
  {
    name: 'Gambia',
    phoneCode: '+220',
    code: 'GM',
    flag: '🇬🇲',
    currency: 'GMD'
  },
  {
    name: 'Georgia',
    phoneCode: '+995',
    code: 'GE',
    flag: '🇬🇪',
    currency: 'GEL'
  },
  {
    name: 'Germany',
    phoneCode: '+49',
    code: 'DE',
    flag: '🇩🇪',
    currency: 'EUR'
  },
  {
    name: 'Ghana',
    phoneCode: '+233',
    code: 'GH',
    flag: '🇬🇭',
    currency: 'GHS'
  },
  {
    name: 'Gibraltar',
    phoneCode: '+350',
    code: 'GI',
    flag: '🇬🇮',
    currency: 'GIP'
  },
  {
    name: 'Greece',
    phoneCode: '+30',
    code: 'GR',
    flag: '🇬🇷',
    currency: 'EUR'
  },
  {
    name: 'Greenland',
    phoneCode: '+299',
    code: 'GL',
    flag: '🇬🇱',
    currency: 'DKK'
  },
  {
    name: 'Grenada',
    phoneCode: '+1473',
    code: 'GD',
    flag: '🇬🇩',
    currency: 'XCD'
  },
  {
    name: 'Guadeloupe',
    phoneCode: '+590',
    code: 'GP',
    flag: '🇬🇵',
    currency: 'EUR'
  },
  {
    name: 'Guam',
    phoneCode: '+1671',
    code: 'GU',
    flag: '🇬🇺',
    currency: 'USD'
  },
  {
    name: 'Guatemala',
    phoneCode: '+502',
    code: 'GT',
    flag: '🇬🇹',
    currency: 'GTQ'
  },
  {
    name: 'Guernsey',
    phoneCode: '+44',
    code: 'GG',
    flag: '🇬🇬',
    currency: 'GBP'
  },
  {
    name: 'Guinea',
    phoneCode: '+224',
    code: 'GN',
    flag: '🇬🇳',
    currency: 'GNF'
  },
  {
    name: 'Guinea-Bissau',
    phoneCode: '+245',
    code: 'GW',
    flag: '🇬🇼',
    currency: 'XOF'
  },
  {
    name: 'Guyana',
    phoneCode: '+592',
    code: 'GY',
    flag: '🇬🇾',
    currency: 'GYD'
  },
  {
    name: 'Haiti',
    phoneCode: '+509',
    code: 'HT',
    flag: '🇭🇹',
    currency: 'HTG'
  },
  {
    name: 'Holy See (Vatican City State)',
    phoneCode: '+379',
    code: 'VA',
    flag: '🇻🇦',
    currency: 'EUR'
  },
  {
    name: 'Honduras',
    phoneCode: '+504',
    code: 'HN',
    flag: '🇭🇳',
    currency: 'HNL'
  },
  {
    name: 'Hong Kong',
    phoneCode: '+852',
    code: 'HK',
    flag: '🇭🇰',
    currency: 'HKD'
  },
  {
    name: 'Hungary',
    phoneCode: '+36',
    code: 'HU',
    flag: '🇭🇺',
    currency: 'HUF'
  },
  {
    name: 'Iceland',
    phoneCode: '+354',
    code: 'IS',
    flag: '🇮🇸',
    currency: 'ISK'
  },
  {
    name: 'India',
    phoneCode: '+91',
    code: 'IN',
    flag: '🇮🇳',
    currency: 'INR'
  },
  {
    name: 'Indonesia',
    phoneCode: '+62',
    code: 'ID',
    flag: '🇮🇩',
    currency: 'IDR'
  },
  {
    name: 'Iran, Islamic Republic of',
    phoneCode: '+98',
    code: 'IR',
    flag: '🇮🇷',
    currency: 'IRR'
  },
  {
    name: 'Iraq',
    phoneCode: '+964',
    code: 'IQ',
    flag: '🇮🇶',
    currency: 'IQD'
  },
  {
    name: 'Ireland',
    phoneCode: '+353',
    code: 'IE',
    flag: '🇮🇪',
    currency: 'EUR'
  },
  {
    name: 'Isle of Man',
    phoneCode: '+44',
    code: 'IM',
    flag: '🇮🇲',
    currency: 'GBP'
  },
  {
    name: 'Israel',
    phoneCode: '+972',
    code: 'IL',
    flag: '🇮🇱',
    currency: 'ILS'
  },
  {
    name: 'Italy',
    phoneCode: '+39',
    code: 'IT',
    flag: '🇮🇹',
    currency: 'EUR'
  },
  {
    name: 'Jamaica',
    phoneCode: '+1876',
    code: 'JM',
    flag: '🇯🇲',
    currency: 'JMD'
  },
  {
    name: 'Japan',
    phoneCode: '+81',
    code: 'JP',
    flag: '🇯🇵',
    currency: 'JPY'
  },
  {
    name: 'Jersey',
    phoneCode: '+44',
    code: 'JE',
    flag: '🇯🇪',
    currency: 'GBP'
  },
  {
    name: 'Jordan',
    phoneCode: '+962',
    code: 'JO',
    flag: '🇯🇴',
    currency: 'JOD'
  },
  {
    name: 'Kazakhstan',
    phoneCode: '+77',
    code: 'KZ',
    flag: '🇰🇿',
    currency: 'KZT'
  },
  {
    name: 'Kenya',
    phoneCode: '+254',
    code: 'KE',
    flag: '🇰🇪',
    currency: 'KES'
  },
  {
    name: 'Kiribati',
    phoneCode: '+686',
    code: 'KI',
    flag: '🇰🇮',
    currency: 'AUD'
  },
  {
    name: "Korea, Democratic People's Republic of",
    phoneCode: '+850',
    code: 'KP',
    flag: '🇰🇵',
    currency: 'KPW'
  },
  {
    name: 'Korea, Republic of',
    phoneCode: '+82',
    code: 'KR',
    flag: '🇰🇷',
    currency: 'KRW'
  },
  {
    name: 'Kuwait',
    phoneCode: '+965',
    code: 'KW',
    flag: '🇰🇼',
    currency: 'KWD'
  },
  {
    name: 'Kyrgyzstan',
    phoneCode: '+996',
    code: 'KG',
    flag: '🇰🇬',
    currency: 'KGS'
  },
  {
    name: "Lao People's Democratic Republic",
    phoneCode: '+856',
    code: 'LA',
    flag: '🇱🇦',
    currency: 'LAK'
  },
  {
    name: 'Latvia',
    phoneCode: '+371',
    code: 'LV',
    flag: '🇱🇻',
    currency: 'EUR'
  },
  {
    name: 'Lebanon',
    phoneCode: '+961',
    code: 'LB',
    flag: '🇱🇧',
    currency: 'LBP'
  },
  {
    name: 'Lesotho',
    phoneCode: '+266',
    code: 'LS',
    flag: '🇱🇸',
    currency: 'LSL'
  },
  {
    name: 'Liberia',
    phoneCode: '+231',
    code: 'LR',
    flag: '🇱🇷',
    currency: 'LRD'
  },
  {
    name: 'Libyan Arab Jamahiriya',
    phoneCode: '+218',
    code: 'LY',
    flag: '🇱🇾',
    currency: 'LYD'
  },
  {
    name: 'Liechtenstein',
    phoneCode: '+423',
    code: 'LI',
    flag: '🇱🇮',
    currency: 'CHF'
  },
  {
    name: 'Lithuania',
    phoneCode: '+370',
    code: 'LT',
    flag: '🇱🇹',
    currency: 'EUR'
  },
  {
    name: 'Luxembourg',
    phoneCode: '+352',
    code: 'LU',
    flag: '🇱🇺',
    currency: 'EUR'
  },
  {
    name: 'Macao',
    phoneCode: '+853',
    code: 'MO',
    flag: '🇲🇴',
    currency: 'MOP'
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    phoneCode: '+389',
    code: 'MK',
    flag: '🇲🇰',
    currency: 'MKD'
  },
  {
    name: 'Madagascar',
    phoneCode: '+261',
    code: 'MG',
    flag: '🇲🇬',
    currency: 'MGA'
  },
  {
    name: 'Malawi',
    phoneCode: '+265',
    code: 'MW',
    flag: '🇲🇼',
    currency: 'MWK'
  },
  {
    name: 'Malaysia',
    phoneCode: '+60',
    code: 'MY',
    flag: '🇲🇾',
    currency: 'MYR'
  },
  {
    name: 'Maldives',
    phoneCode: '+960',
    code: 'MV',
    flag: '🇲🇻',
    currency: 'MVR'
  },
  {
    name: 'Mali',
    phoneCode: '+223',
    code: 'ML',
    flag: '🇲🇱',
    currency: 'XOF'
  },
  {
    name: 'Malta',
    phoneCode: '+356',
    code: 'MT',
    flag: '🇲🇹',
    currency: 'EUR'
  },
  {
    name: 'Marshall Islands',
    phoneCode: '+692',
    code: 'MH',
    flag: '🇲🇭',
    currency: 'USD'
  },
  {
    name: 'Martinique',
    phoneCode: '+596',
    code: 'MQ',
    flag: '🇲🇶',
    currency: 'EUR'
  },
  {
    name: 'Mauritania',
    phoneCode: '+222',
    code: 'MR',
    flag: '🇲🇷',
    currency: 'MRO'
  },
  {
    name: 'Mauritius',
    phoneCode: '+230',
    code: 'MU',
    flag: '🇲🇺',
    currency: 'MUR'
  },
  {
    name: 'Mayotte',
    phoneCode: '+262',
    code: 'YT',
    flag: '🇾🇹',
    currency: 'EUR'
  },
  {
    name: 'Mexico',
    phoneCode: '+52',
    code: 'MX',
    flag: '🇲🇽',
    currency: 'MXN'
  },
  {
    name: 'Micronesia, Federated States of',
    phoneCode: '+691',
    code: 'FM',
    flag: '🇫🇲',
    currency: 'USD'
  },
  {
    name: 'Moldova, Republic of',
    phoneCode: '+373',
    code: 'MD',
    flag: '🇲🇩',
    currency: 'MDL'
  },
  {
    name: 'Monaco',
    phoneCode: '+377',
    code: 'MC',
    flag: '🇲🇨',
    currency: 'EUR'
  },
  {
    name: 'Mongolia',
    phoneCode: '+976',
    code: 'MN',
    flag: '🇲🇳',
    currency: 'MNT'
  },
  {
    name: 'Montenegro',
    phoneCode: '+382',
    code: 'ME',
    flag: '🇲🇪',
    currency: 'EUR'
  },
  {
    name: 'Montserrat',
    phoneCode: '+1664',
    code: 'MS',
    flag: '🇲🇸',
    currency: 'XCD'
  },
  {
    name: 'Morocco',
    phoneCode: '+212',
    code: 'MA',
    flag: '🇲🇦',
    currency: 'MAD'
  },
  {
    name: 'Mozambique',
    phoneCode: '+258',
    code: 'MZ',
    flag: '🇲🇿',
    currency: 'MZN'
  },
  {
    name: 'Myanmar',
    phoneCode: '+95',
    code: 'MM',
    flag: '🇲🇲',
    currency: 'MMK'
  },
  {
    name: 'Namibia',
    phoneCode: '+264',
    code: 'NA',
    flag: '🇳🇦',
    currency: 'NAD'
  },
  {
    name: 'Nauru',
    phoneCode: '+674',
    code: 'NR',
    flag: '🇳🇷',
    currency: 'AUD'
  },
  {
    name: 'Nepal',
    phoneCode: '+977',
    code: 'NP',
    flag: '🇳🇵',
    currency: 'NPR'
  },
  {
    name: 'Netherlands',
    phoneCode: '+31',
    code: 'NL',
    flag: '🇳🇱',
    currency: 'EUR'
  },
  {
    name: 'New Caledonia',
    phoneCode: '+687',
    code: 'NC',
    flag: '🇳🇨',
    currency: 'XPF'
  },
  {
    name: 'New Zealand',
    phoneCode: '+64',
    code: 'NZ',
    flag: '🇳🇿',
    currency: 'NZD'
  },
  {
    name: 'Nicaragua',
    phoneCode: '+505',
    code: 'NI',
    flag: '🇳🇮',
    currency: 'NIO'
  },
  {
    name: 'Niger',
    phoneCode: '+227',
    code: 'NE',
    flag: '🇳🇪',
    currency: 'XOF'
  },
  {
    name: 'Nigeria',
    phoneCode: '+234',
    code: 'NG',
    flag: '🇳🇬',
    currency: 'NGN'
  },
  {
    name: 'Niue',
    phoneCode: '+683',
    code: 'NU',
    flag: '🇳🇺',
    currency: 'NZD'
  },
  {
    name: 'Norfolk Island',
    phoneCode: '+672',
    code: 'NF',
    flag: '🇳🇫',
    currency: 'AUD'
  },
  {
    name: 'Northern Mariana Islands',
    phoneCode: '+1670',
    code: 'MP',
    flag: '🇲🇵',
    currency: 'USD'
  },
  {
    name: 'Norway',
    phoneCode: '+47',
    code: 'NO',
    flag: '🇳🇴',
    currency: 'NOK'
  },
  {
    name: 'Oman',
    phoneCode: '+968',
    code: 'OM',
    flag: '🇴🇲',
    currency: 'OMR'
  },
  {
    name: 'Pakistan',
    phoneCode: '+92',
    code: 'PK',
    flag: '🇵🇰',
    currency: 'PKR'
  },
  {
    name: 'Palau',
    phoneCode: '+680',
    code: 'PW',
    flag: '🇵🇼',
    currency: 'USD'
  },
  {
    name: 'Palestinian Territory, Occupied',
    phoneCode: '+970',
    code: 'PS',
    flag: '🇵🇸',
    currency: 'ILS'
  },
  {
    name: 'Panama',
    phoneCode: '+507',
    code: 'PA',
    flag: '🇵🇦',
    currency: 'PAB'
  },
  {
    name: 'Papua New Guinea',
    phoneCode: '+675',
    code: 'PG',
    flag: '🇵🇬',
    currency: 'PGK'
  },
  {
    name: 'Paraguay',
    phoneCode: '+595',
    code: 'PY',
    flag: '🇵🇾',
    currency: 'PYG'
  },
  {
    name: 'Peru',
    phoneCode: '+51',
    code: 'PE',
    flag: '🇵🇪',
    currency: 'PEN'
  },
  {
    name: 'Philippines',
    phoneCode: '+63',
    code: 'PH',
    flag: '🇵🇭',
    currency: 'PHP'
  },
  {
    name: 'Pitcairn',
    phoneCode: '+872',
    code: 'PN',
    flag: '🇵🇳',
    currency: 'NZD'
  },
  {
    name: 'Poland',
    phoneCode: '+48',
    code: 'PL',
    flag: '🇵🇱',
    currency: 'PLN'
  },
  {
    name: 'Portugal',
    phoneCode: '+351',
    code: 'PT',
    flag: '🇵🇹',
    currency: 'EUR'
  },
  {
    name: 'Puerto Rico',
    phoneCode: '+1939',
    code: 'PR',
    flag: '🇵🇷',
    currency: 'USD'
  },
  {
    name: 'Qatar',
    phoneCode: '+974',
    code: 'QA',
    flag: '🇶🇦',
    currency: 'QAR'
  },
  {
    name: 'Romania',
    phoneCode: '+40',
    code: 'RO',
    flag: '🇷🇴',
    currency: 'RON'
  },
  {
    name: 'Russia',
    phoneCode: '+7',
    code: 'RU',
    flag: '🇷🇺',
    currency: 'RUB'
  },
  {
    name: 'Rwanda',
    phoneCode: '+250',
    code: 'RW',
    flag: '🇷🇼',
    currency: 'RWF'
  },
  {
    name: 'Réunion',
    phoneCode: '+262',
    code: 'RE',
    flag: '🇷🇪',
    currency: 'EUR'
  },
  {
    name: 'Saint Barthélemy',
    phoneCode: '+590',
    code: 'BL',
    flag: '🇧🇱',
    currency: 'EUR'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    phoneCode: '+290',
    code: 'SH',
    flag: '🇸🇭',
    currency: 'SHP'
  },
  {
    name: 'Saint Kitts and Nevis',
    phoneCode: '+1869',
    code: 'KN',
    flag: '🇰🇳',
    currency: 'XCD'
  },
  {
    name: 'Saint Lucia',
    phoneCode: '+1758',
    code: 'LC',
    flag: '🇱🇨',
    currency: 'XCD'
  },
  {
    name: 'Saint Martin',
    phoneCode: '+590',
    code: 'MF',
    flag: '🇲🇫',
    currency: 'EUR'
  },
  {
    name: 'Saint Pierre and Miquelon',
    phoneCode: '+508',
    code: 'PM',
    flag: '🇵🇲',
    currency: 'EUR'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    phoneCode: '+1784',
    code: 'VC',
    flag: '🇻🇨',
    currency: 'XCD'
  },
  {
    name: 'Samoa',
    phoneCode: '+685',
    code: 'WS',
    flag: '🇼🇸',
    currency: 'WST'
  },
  {
    name: 'San Marino',
    phoneCode: '+378',
    code: 'SM',
    flag: '🇸🇲',
    currency: 'EUR'
  },
  {
    name: 'Sao Tome and Principe',
    phoneCode: '+239',
    code: 'ST',
    flag: '🇸🇹',
    currency: 'STD'
  },
  {
    name: 'Saudi Arabia',
    phoneCode: '+966',
    code: 'SA',
    flag: '🇸🇦',
    currency: 'SAR'
  },
  {
    name: 'Senegal',
    phoneCode: '+221',
    code: 'SN',
    flag: '🇸🇳',
    currency: 'XOF'
  },
  {
    name: 'Serbia',
    phoneCode: '+381',
    code: 'RS',
    flag: '🇷🇸',
    currency: 'RSD'
  },
  {
    name: 'Seychelles',
    phoneCode: '+248',
    code: 'SC',
    flag: '🇸🇨',
    currency: 'SCR'
  },
  {
    name: 'Sierra Leone',
    phoneCode: '+232',
    code: 'SL',
    flag: '🇸🇱',
    currency: 'SLL'
  },
  {
    name: 'Singapore',
    phoneCode: '+65',
    code: 'SG',
    flag: '🇸🇬',
    currency: 'SGD'
  },
  {
    name: 'Slovakia',
    phoneCode: '+421',
    code: 'SK',
    flag: '🇸🇰',
    currency: 'EUR'
  },
  {
    name: 'Slovenia',
    phoneCode: '+386',
    code: 'SI',
    flag: '🇸🇮',
    currency: 'EUR'
  },
  {
    name: 'Solomon Islands',
    phoneCode: '+677',
    code: 'SB',
    flag: '🇸🇧',
    currency: 'SBD'
  },
  {
    name: 'Somalia',
    phoneCode: '+252',
    code: 'SO',
    flag: '🇸🇴',
    currency: 'SOS'
  },
  {
    name: 'South Africa',
    phoneCode: '+27',
    code: 'ZA',
    flag: '🇿🇦',
    currency: 'ZAR'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    phoneCode: '+500',
    code: 'GS',
    flag: '🇬🇸',
    currency: 'GBP'
  },
  {
    name: 'Spain',
    phoneCode: '+34',
    code: 'ES',
    language: 'ES',
    flag: '🇪🇸',
    currency: 'EUR'
  },
  {
    name: 'Sri Lanka',
    phoneCode: '+94',
    code: 'LK',
    flag: '🇱🇰',
    currency: 'LKR'
  },
  {
    name: 'Sudan',
    phoneCode: '+249',
    code: 'SD',
    flag: '🇸🇩',
    currency: 'SDG'
  },
  {
    name: 'South Sudan',
    phoneCode: '+211',
    code: 'SS',
    flag: '🇸🇸',
    currency: 'SSP'
  },
  {
    name: 'Suriname',
    phoneCode: '+597',
    code: 'SR',
    flag: '🇸🇷',
    currency: 'SRD'
  },
  {
    name: 'Svalbard and Jan Mayen',
    phoneCode: '+47',
    code: 'SJ',
    flag: '🇸🇯',
    currency: 'NOK'
  },
  {
    name: 'Swaziland',
    phoneCode: '+268',
    code: 'SZ',
    flag: '🇸🇿',
    currency: 'SZL'
  },
  {
    name: 'Sweden',
    phoneCode: '+46',
    code: 'SE',
    flag: '🇸🇪',
    currency: 'SEK'
  },
  {
    name: 'Switzerland',
    phoneCode: '+41',
    code: 'CH',
    flag: '🇨🇭',
    currency: 'CHF'
  },
  {
    name: 'Syrian Arab Republic',
    phoneCode: '+963',
    code: 'SY',
    flag: '🇸🇾',
    currency: 'SYP'
  },
  {
    name: 'Taiwan, Province of China',
    phoneCode: '+886',
    code: 'TW',
    flag: '🇹🇼',
    currency: 'TWD'
  },
  {
    name: 'Tajikistan',
    phoneCode: '+992',
    code: 'TJ',
    flag: '🇹🇯',
    currency: 'TJS'
  },
  {
    name: 'Tanzania, United Republic of',
    phoneCode: '+255',
    code: 'TZ',
    flag: '🇹🇿',
    currency: 'TZS'
  },
  {
    name: 'Thailand',
    phoneCode: '+66',
    code: 'TH',
    flag: '🇹🇭',
    currency: 'THB'
  },
  {
    name: 'Timor-Leste',
    phoneCode: '+670',
    code: 'TL',
    flag: '🇹🇱',
    currency: 'USD'
  },
  {
    name: 'Togo',
    phoneCode: '+228',
    code: 'TG',
    flag: '🇹🇬',
    currency: 'XOF'
  },
  {
    name: 'Tokelau',
    phoneCode: '+690',
    code: 'TK',
    flag: '🇹🇰',
    currency: 'NZD'
  },
  {
    name: 'Tonga',
    phoneCode: '+676',
    code: 'TO',
    flag: '🇹🇴',
    currency: 'TOP'
  },
  {
    name: 'Trinidad and Tobago',
    phoneCode: '+1868',
    code: 'TT',
    flag: '🇹🇹',
    currency: 'TTD'
  },
  {
    name: 'Tunisia',
    phoneCode: '+216',
    code: 'TN',
    flag: '🇹🇳',
    currency: 'TND'
  },
  {
    name: 'Turkey',
    phoneCode: '+90',
    code: 'TR',
    flag: '🇹🇷',
    currency: 'TRY'
  },
  {
    name: 'Turkmenistan',
    phoneCode: '+993',
    code: 'TM',
    flag: '🇹🇲',
    currency: 'TMT'
  },
  {
    name: 'Turks and Caicos Islands',
    phoneCode: '+1649',
    code: 'TC',
    flag: '🇹🇨',
    currency: 'USD'
  },
  {
    name: 'Tuvalu',
    phoneCode: '+688',
    code: 'TV',
    flag: '🇹🇻',
    currency: 'AUD'
  },
  {
    name: 'Uganda',
    phoneCode: '+256',
    code: 'UG',
    flag: '🇺🇬',
    currency: 'UGX'
  },
  {
    name: 'Ukraine',
    phoneCode: '+380',
    code: 'UA',
    flag: '🇺🇦',
    currency: 'UAH'
  },
  {
    name: 'United Arab Emirates',
    phoneCode: '+971',
    code: 'AE',
    flag: '🇦🇪',
    currency: 'AED'
  },
  {
    name: 'United Kingdom',
    phoneCode: '+44',
    code: 'GB',
    flag: '🇬🇧',
    currency: 'GBP'
  },
  {
    name: 'United States',
    phoneCode: '+1',
    code: 'US',
    language: 'EN',
    flag: '🇺🇸',
    currency: 'USD'
  },
  {
    name: 'Uruguay',
    phoneCode: '+598',
    code: 'UY',
    flag: '🇺🇾',
    currency: 'UYU'
  },
  {
    name: 'Uzbekistan',
    phoneCode: '+998',
    code: 'UZ',
    flag: '🇺🇿',
    currency: 'UZS'
  },
  {
    name: 'Vanuatu',
    phoneCode: '+678',
    code: 'VU',
    flag: '🇻🇺',
    currency: 'VUV'
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    phoneCode: '+58',
    code: 'VE',
    flag: '🇻🇪',
    currency: 'VEF'
  },
  {
    name: 'Viet Nam',
    phoneCode: '+84',
    code: 'VN',
    flag: '🇻🇳',
    currency: 'VND'
  },
  {
    name: 'Virgin Islands, British',
    phoneCode: '+1284',
    code: 'VG',
    flag: '🇻🇬',
    currency: 'USD'
  },
  {
    name: 'Virgin Islands, U.S.',
    phoneCode: '+1340',
    code: 'VI',
    flag: '🇻🇮',
    currency: 'USD'
  },
  {
    name: 'Wallis and Futuna',
    phoneCode: '+681',
    code: 'WF',
    flag: '🇼🇫',
    currency: 'XPF'
  },
  {
    name: 'Yemen',
    phoneCode: '+967',
    code: 'YE',
    flag: '🇾🇪',
    currency: 'YER'
  },
  {
    name: 'Zambia',
    phoneCode: '+260',
    code: 'ZM',
    flag: '🇿🇲',
    currency: 'ZMW'
  },
  {
    name: 'Zimbabwe',
    phoneCode: '+263',
    code: 'ZW',
    flag: '🇿🇼',
    currency: 'ZWL'
  },
  {
    name: 'Åland Islands',
    phoneCode: '+358',
    code: 'AX',
    flag: '🇦🇽',
    currency: 'EUR'
  }
]

type GetCountriesOptions = {
  includeExtra?: boolean
  label?: (country: Country) => string
}

const getCountriesOptions = ({ includeExtra = false, label }: GetCountriesOptions): Option[] => {
  return countries
    .map(country => ({
      value: country.code,
      label: label ? label(country) : `${country.flag} ${country.name}`,
      extra: includeExtra ? country : undefined
    }))
}

type GetLanguageOptions = {
  includeExtra?: boolean
  label?: (country: Country) => string
}

const getLanguageOptions = ({ includeExtra = false, label }: GetLanguageOptions): Option[] => {
  return countries
    .filter(country => !!country.language)
    .map(country => ({
      value: country.language!,
      label: label ? label(country) : `${country.flag} ${i18next.t(`datas:languages.${country.language}`)}`,
      extra: includeExtra ? country : undefined
    }))
}

const getPhoneCode = (countryCode: Countries): string => {
  return countries.find(country => country.code === countryCode)?.phoneCode || ''
}

const getCurrency = (countryCode: Countries): PlanCurrencies => {
  return countries.find(country => country.code === countryCode)?.currency === 'EUR' ? 'EUR' : 'USD'
}

export { countries, getCountriesOptions, getLanguageOptions, getPhoneCode, getCurrency }
