
import { useState, useEffect, useCallback } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { ExclamationCircleIcon, CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'

import { twMerge } from 'tailwind-merge'
import { getAutocompleteAddress } from 'utils/maps'
import { useTranslation } from 'react-i18next'
import { Option } from 'types'

type AddressProps = {
  id: string
  label?: string
  info?: string
  readOnly?: boolean
  placeholder?: string
  personal?: boolean
  required?: boolean
}

const Address = ({ id, label, info, readOnly = false, placeholder, personal, required }: AddressProps) => {
  const { i18n } = useTranslation()
  const { control, resetField, watch, formState: { errors } } = useFormContext()

  const [value, setValue] = useState('')
  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    getAutocompleteAddress(value, { language: i18n.language.split('-')[0], personal })
      .then((predictions) => {
        setOptions(predictions.map(prediction => ({ value: prediction.place_id, label: prediction.description })))
      })
  }, [value])

  const myReset = useCallback(() => {
    setValue('')
    resetField(id)
  }, [resetField, id, setValue])

  return (
    <div className="w-full">
      {label
        ? <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1 pl-1">
            <span>{label}</span>
            {required ? <span className='ml-1'>*</span> : null}
          </label>
        : null
      }

      <div className="relative w-full">
        <Controller
          render={({ field }) => (
            <Combobox as="div" {...field}>
              <div className="relative">
                <Combobox.Input
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-light focus:outline-none focus:ring-primary-light sm:text-sm'
                  placeholder={placeholder}
                  readOnly={readOnly}
                  onChange={(event) => setValue(event.target.value)}
                  displayValue={() => watch(id)?.label}// to display the palce older when using my reset
                  // displayValue={(option: Option) => option.label}
                />
                {!readOnly
                  ? <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>
                  : null
                }

                {options.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {options.map((option: Option) => (
                      <Combobox.Option
                        key={option.value}
                        value={option}
                        className={({ active }) =>
                          twMerge(
                            'relative cursor-pointer select-none py-2 pl-3 pr-9',
                            active ? 'bg-primary-light text-white' : 'text-gray-900'
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span className={twMerge('block truncate', selected ? 'font-semibold' : '')}>{option.label}</span>

                            {selected && (
                              <span
                                className={twMerge(
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                  active ? 'text-white' : 'text-primary-light'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          )}
          name={id}
          control={control}
          defaultValue={undefined}
        />

        {errors[id]
          ? <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          : null
        }
        {watch(id) && !readOnly
          ? <div className="absolute inset-y-0 right-8 flex items-center cursor-pointer" onClick={myReset}>
              <XCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
            </div>
          : null
        }
      </div>

      {errors[id]
        ? <p className="mt-1 mx-2 text-xs text-red-600" id={`${id}-error`}>
            {(errors as any)[id]?.message}
          </p>
        : info
          ? <p className="mt-2 text-sm text-gray-500" id={`${id}-info`}>
              {info}
            </p>
          : null
      }
    </div>
  )
}

export default Address
