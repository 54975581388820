import { createContext, useState, useContext, useEffect, useCallback, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import setDateFnsDefaultOptions from 'date-fns/setDefaultOptions'
import { es as esDateFns, fr as frDateFns } from 'date-fns/locale'

import axios, { AxiosError } from 'axios'

import { onAuthStateChanged, signOut } from 'firebase/auth'

import { useFirebase } from './firebase'

import { UCompany, User } from 'types'
import { useNavigate } from 'react-router-dom'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'

type UserProviderProps = {
  children: ReactNode
}

 type InitialValues = {
  loading: boolean
  user?: User
  activeCompany?: UCompany
  updateUser: (user: User) => void
  changeActiveCompany: (id: string) => UCompany
  addCompany: (company: UCompany) => void
  updateCompany: (company: UCompany) => UCompany
  logout: () => void
}

const initialValues: InitialValues = {
  loading: true,
  user: undefined,
  activeCompany: undefined,
  updateUser: (user: User) => {},
  changeActiveCompany: (id: string) => ({} as UCompany),
  addCompany: (company: UCompany) => {},
  updateCompany: (company: UCompany) => ({} as UCompany),
  logout: () => {}
}

const UserContext = createContext(initialValues)

const languageToFnsLanguage = {
  FR: frDateFns,
  ES: esDateFns,
  EN: undefined
}

const UserProvider = ({ children }: UserProviderProps) => {
  const { i18n } = useTranslation()
  const { auth } = useFirebase()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [activeCompany, setActiveCompany] = useState<UCompany | undefined>(undefined)

  useEffect(() => {
    const myInterceptor = axios.interceptors.response.use(async (res: AxiosError | any) => {
      if (res.code === 'ERR_BAD_REQUEST') {
        if (res.response.status === 403) {
          location.reload()
        }
      }
      return res
    }, (error) => {
      return Promise.reject(error)
    })
    return () => axios.interceptors.response.eject(myInterceptor)
  }, [])

  const changeActiveCompany = (id: string): UCompany => {
    if (!user) throw new Error('')
    if (activeCompany?.id === id) return activeCompany
    const newActiveCompany = user?.companies.find((c: UCompany) => c.id === id)
    if (newActiveCompany) {
      setActiveCompany(newActiveCompany)
      localStorage.setItem('clokiziActiveCompany', newActiveCompany.id)
      return newActiveCompany
    }
    throw new Error('')
  }

  const addCompany = (newCompany: UCompany) => {
    setUser(prevUser => prevUser
      ? ({
          ...prevUser,
          companies: [
            ...prevUser.companies,
            newCompany
          ]
        })
      : undefined
    )
    setActiveCompany(newCompany)
  }

  const updateCompany = (updatedCompany: UCompany): UCompany => {
    setUser(prevUser => prevUser
      ? ({
          ...prevUser,
          companies: prevUser.companies.map(company => company.id === updatedCompany.id ? updatedCompany : company)
        })
      : undefined
    )
    if (activeCompany?.id === updatedCompany.id) {
      setActiveCompany(updatedCompany)
    }
    return updatedCompany
  }

  const updateUser = useCallback((newUser: User) => {
    setUser((prev?: User) => ({
      ...prev,
      ...newUser
    }))
    newUser.language && i18n.changeLanguage(newUser.language.toLowerCase())
    newUser.language && setDateFnsDefaultOptions({ locale: languageToFnsLanguage[newUser.language] || undefined })
  }, [i18n, setUser])

  const getUser = async () => {
    const res = await axios.get<{data: User}, any>('/users/me')
    if (!res?.data?.data) {
      logout()
      return
    }
    const user = res.data.data
    if (!user.language) {
      axios.put('users/me', {
        language: i18n.language.split('-')[0].toUpperCase()
      })
      user.language = i18n.language.split('-')[0].toUpperCase()
    }

    const notAgentCompanies = user.companies.filter((company: any) => company.myRole !== 'AGENT')

    if (!notAgentCompanies.length && user.companies.length) {
      navigate('auth/agent')
      logout()
      return
    }

    user.companies = notAgentCompanies

    updateUser(user)
    const storedCompany = localStorage.getItem('clokiziActiveCompany') ? user.companies.find((rw: UCompany) => rw.id === localStorage.getItem('clokiziActiveCompany')) : undefined
    let newActiveCompany
    if (storedCompany) {
      newActiveCompany = storedCompany
    } else if (user.companies.length) {
      newActiveCompany = user.companies[0]
    }

    if (newActiveCompany && !['ADMIN', 'MANAGER'].includes(newActiveCompany.role)) {
      logout()
    } else if (newActiveCompany) {
      setActiveCompany(newActiveCompany)
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser && user) {
        const token = await firebaseUser.getIdToken()
        axios.defaults.headers.common.authorization = token
      } else if (firebaseUser && !user) {
        const token = await firebaseUser.getIdToken()
        axios.defaults.headers.common.authorization = token
        await getUser()
        setLoading(false)
      } else if (!firebaseUser) {
        setUser(undefined)
        setActiveCompany(undefined)
        axios.defaults.headers.common.authorization = undefined
        setLoading(false)
      }
    })
    return unsubscribe
  }, [])

  const logout = async () => {
    await signOut(auth)
  }

  return (
    <UserContext.Provider value={{
      loading,
      user,
      activeCompany,
      updateUser,
      changeActiveCompany,
      addCompany,
      updateCompany,
      logout
    }}>
      {children}
    </UserContext.Provider>
  )
}

const useUser = () => {
  return useContext(UserContext)
}

export { UserProvider, useUser }
