// generic
import global from './global_fr.json'
import datas from './datas_fr.json'
import menu from './menu_fr.json'
import offers from './offers_fr.json'
import inputs from './inputs_fr.json'
// pages auth
import login from './login_fr.json'
import register from './register_fr.json'
import reset from './reset_fr.json'
import forgot from './forgot_fr.json'
import welcome from './welcome_fr.json'
import onboarding from './onboarding_fr.json'
import agent from './agent_fr.json'
// pages app
import dashboard from './dashboard_fr.json'
import interventions from './interventions_fr.json'
import intervention from './intervention_fr.json'
import settings from './settings_fr.json'
import users from './users_fr.json'
import customers from './customers_fr.json'
import profile from './profile_fr.json'

export default {
  // generic
  global,
  datas,
  offers,
  menu,
  inputs,
  // pages auth
  login,
  register,
  reset,
  welcome,
  forgot,
  onboarding,
  agent,
  // pages app
  dashboard,
  interventions,
  intervention,
  settings,
  users,
  customers,
  profile
}
