import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, Controller, FieldValues } from 'react-hook-form'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'

import { twMerge } from 'tailwind-merge'

import type { Option } from 'types'

type SelectInputProps = {
  id: string
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  options: Option[]
  error?: any
  reset?: any
  multiple?: boolean
  required?: boolean
  containerClassName?: string
  classNameLabel?: string
  canSearch?: boolean
  displaySelect?: (c: Option) => string
}

const SelectInput = ({ id, value, onChange, label, placeholder, readOnly, disabled, options, error, myReset, multiple, required, containerClassName, classNameLabel, canSearch, displaySelect }: SelectInputProps & FieldValues) => {
  const { t } = useTranslation()

  const valueLabel: string | null = useMemo(() => {
    const selectedOption = options.filter(option => multiple ? value.includes(option.value) : option.value === value).map(option => option.label)
    return selectedOption.length ? selectedOption.join(', ') : null
  }, [value, options])

  const [search, setSearch] = useState<string>('')
  const filteredOptions = useMemo(() => options.filter(option => option.label.replaceAll(' ', '').toLowerCase().includes(search.toLowerCase())), [search, options])

  useEffect(() => {
    setSearch('')
  }, [value])

  return readOnly || disabled
    ? <div className="flex-1">
      {label
        ? <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1 pl-1">
            <span>{label}</span>
            {required ? <span className='ml-1'>*</span> : null}
          </label>
        : null
      }
      <div className="sm:text-sm pl-3">{valueLabel || '-'}</div>
    </div>
    : <div className={twMerge('w-full', containerClassName)}>
      <Listbox value={value} onChange={onChange} disabled={readOnly || disabled} multiple={multiple}>
        {({ open }) => (
          <div className='w-full'>
            {label
              ? <Listbox.Label className="block text-sm font-medium text-gray-700 mb-1 pl-1">
                  <span>{label}</span>
                  {required ? <span className='ml-1'>*</span> : null}
                </Listbox.Label>
                // <Listbox.Label className="block text-sm font-normal text-custom-gray4 mb-1">{label}</Listbox.Label>
              : null
            }
            <div className="relative">
              <Listbox.Button className={twMerge('relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 h-10 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm', classNameLabel)}>
                <span className={twMerge('block truncate', valueLabel ? '' : 'text-gray-400')}>{!multiple && displaySelect ? displaySelect(options?.find(o => o.value === value)!) : valueLabel || placeholder || t('global.select.placeholder')}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={twMerge('absolute z-10 mt-1 max-h-60 w-fit flex rounded-md bg-white py-1 text-base shadow-xl border border-gray-300 ring-1 ring-primary ring-opacity-5 focus:outline-none sm:text-sm', canSearch ? 'pt-10' : '')}>
                  <Listbox.Options className='flex-1 overflow-auto min-w-[100px]'>
                  {canSearch ? <input onClick={e => e.stopPropagation()} value={search} onChange={e => setSearch(e.target.value)} className="w-full border-b border-b-gray-300 p-2 absolute top-0 outline-none ring-0 text-gray-600 rounded-t-md bg-gray-50" placeholder={t('inputs:search.placeholder')!} /> : null}
                    {filteredOptions.length
                      ? filteredOptions
                        .map((option: Option) => (
                          <Listbox.Option
                            key={option.value}
                            className={({ active }) =>
                              twMerge(
                                active ? 'text-white bg-primary' : 'text-gray-900',
                                'cursor-pointer select-none py-2 px-3'
                              )
                            }
                            value={option.value}
                          >
                            {({ selected, active }) => (
                              <div className="flex items-center gap-2">
                                <span className={twMerge('block truncate', selected ? 'font-semibold text-primary' : 'font-normal', active ? 'text-white' : '')}>
                                  {option.label}
                                </span>

                                {selected ? <CheckIcon className={twMerge('h-5 w-5', active ? 'text-white' : 'text-primary')} aria-hidden="true" /> : null}
                              </div>
                            )}
                          </Listbox.Option>
                        ))
                      : <div className='text-center font-medium'>No result</div>
                    }
                  </Listbox.Options>
                </div>
              </Transition>

              {error
                ? <div className="pointer-events-none absolute inset-y-0 right-14 flex items-center">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                : null
              }
              {myReset && value?.length && !readOnly && !disabled
                ? <div className="absolute inset-y-0 right-8 flex items-center cursor-pointer" onClick={myReset}>
                    <XCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                  </div>
                : null
              }
            </div>
          </div>
        )}
      </Listbox>

      {error
        ? <p className="mt-1 mx-2 text-xs text-red-600" id={`${id}-error`}>
            {error.message}
          </p>
        : null
      }
    </div>
}

type SelectProps = {
  id: string
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  options: Option[]
  disableReset?: boolean
  multiple?: boolean
  required?: boolean
  canSearch?: boolean
}

const Select = ({ id, label, placeholder, readOnly, disabled, options, disableReset, multiple, required, canSearch }: SelectProps) => {
  const { control, formState: { errors }, resetField } = useFormContext()

  return (
    <Controller
      render={({ field }) => <SelectInput {...field} id={id} multiple={multiple} myReset={!disableReset ? () => resetField(id) : undefined} label={label} placeholder={placeholder} readOnly={readOnly} disabled={disabled} options={options} error={errors[id]} required={required} canSearch={canSearch} /> }
      name={id}
      control={control}
      defaultValue={multiple ? [] : undefined}
    />
  )
}

export { SelectInput as IsolatedSelect }

export default Select
