import { useMemo, useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

import { getCountriesOptions, getPhoneCode } from 'utils/datas/countries'
import type { Countries, Option } from 'types'

import { twMerge } from 'tailwind-merge'

import { useUser } from 'contexts'
import { IsolatedSelect } from './select'

type PhoneProps = {
  id: string
  label?: string
  info?: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  initValue?: string
}

const Phone = ({ id, label, info, disabled = false, placeholder, required, initValue }: PhoneProps) => {
  const { setValue, watch, formState: { errors } } = useFormContext()
  const { activeCompany } = useUser()
  const [phone, setPhone] = useState<string>('')
  const [countryCode, setCountryCode] = useState<Countries>('FR')

  useEffect(() => {
    if (!disabled) {
      const code = getPhoneCode(countryCode)
      setValue(id, phone && code ? `${code}${phone}` : undefined)
    }
  }, [phone, countryCode])

  const countriesOptions: Option[] = useMemo(() => getCountriesOptions({
    label: (country) => `${country.flag} ${country.name} (${country.phoneCode})`,
    includeExtra: true
  }), [])

  useEffect(() => {
    if (activeCompany?.country) {
      setCountryCode(activeCompany?.country)
    }
  }, [activeCompany, countriesOptions])

  useEffect(() => {
    if (!initValue) return
    const phoneCode: Countries = (countriesOptions.find(countryOption => initValue?.includes(countryOption.extra?.phoneCode))?.value || 'FR') as Countries
    const code = getPhoneCode(phoneCode)
    const phonePhone = initValue.replace(code, '')
    setCountryCode(phoneCode)
    setPhone(phonePhone)
  }, [initValue])

  return (
    <div className="flex-1">
      {label
        ? <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1 pl-1">
            <span>{label}</span>
            {required ? <span className='ml-1'>*</span> : null}
          </label>
        : null
      }

      {disabled
        ? <div className="sm:text-sm pl-3">{watch(id)}</div>
        : <div className={twMerge('shadow-sm relative flex w-full appearance-none rounded-md sm:text-sm border border-gray-300 focus-within:ring-1 focus-within:ring-primary focus-within:border-primary', disabled ? 'border-0 ring-0' : '')}>
            {/* <div className="absolute inset-y-0 left-0 flex items-center"> */}
              <IsolatedSelect
                id={`${id}-country-code`}
                name="country"
                autoComplete="country"
                // className={twMerge('h-full absolute rounded-l-md border-transparent bg-transparent py-0 px-2 w-24 focus:border-primary focus:ring-primary sm:text-sm border-r-1 border-r-gray-300', disabled ? 'border-0 ring-0 shadow-none bg-none opacity-100' : '')}
                containerClassName='w-fit'
                classNameLabel='focus:outline-none focus:ring-0 bg-transparent shadow-none border-none rounded-md'
                value={countryCode}
                disabled={disabled}
                onChange={(value: Countries) => setCountryCode(value)}
                options={countriesOptions}
                canSearch
                displaySelect={c => `${c.extra?.flag} ${c.extra?.phoneCode}`}
              />
              {/* <select
                id={`${id}-country-code`}
                name="country"
                autoComplete="country"
                className={twMerge('h-full absolute rounded-l-md border-transparent bg-transparent py-0 px-2 w-24 focus:border-primary focus:ring-primary sm:text-sm border-r-1 border-r-gray-300', disabled ? 'border-0 ring-0 shadow-none bg-none opacity-100' : '')}
                value={countryCode}
                disabled={disabled}
                onChange={(e) => setCountryCode(e.target.value as Countries)}
              >
                {countriesOptions.map((country: Option) => <option key={country.value} value={country.value}>
                  {country.label}
                </option>)}
              </select> */}
            {/* </div> */}
            <input
              type="number"
              onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              autoComplete="tel-national"
              className='flex-1 block w-full appearance-none focus:ring-0 shadow-none border-y-0 border-r-0 outline-none ring-0 sm:text-sm placeholder-gray-400 h-10 border-l-1 border-l-gray-300 focus:border-l-gray-300 rounded-r-md'
              min={0}
              placeholder={placeholder}
              disabled={disabled}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            {(errors as any)?.[id]
              ? <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
              : null
            }
          </div>}

      {(errors as any)?.[id]
        ? <p className="mt-1 mx-2 text-xs text-red-600" id={`${id}-error`}>
            {(errors as any)?.[id]?.message}
          </p>
        : info
          ? <p className="mt-2 text-sm text-gray-500" id={`${id}-info`}>
              {info}
            </p>
          : null
      }
    </div>
  )
}

export default Phone
