import { FieldError } from 'react-hook-form'

import { twMerge } from 'tailwind-merge'

import type { Option } from 'types'

type ButtonGroupProps = {
  value: string
  onChange: (value: string) => void
  readOnly?: boolean
  options: Option[]
  error?: FieldError
  className?: string
}

const ButtonGroup = ({ value, onChange, readOnly, error, options, className }: ButtonGroupProps) => {
  return (
    <div className={twMerge('w-full', className)}>
      <div className="isolate inline-flex rounded-md shadow-sm relative w-full">
        {options.map((option: Option, index: number) => <button
          type='button'
          key={option.value}
          className={twMerge(
            'relative flex-1 -ml-px flex justify-center items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700',
            index === 0 ? 'rounded-l-md' : '',
            index === options.length - 1 ? 'rounded-r-md' : '',
            value === option.value ? 'z-10 border-primary outline-none ring-1 ring-primary' : 'hover:bg-gray-50'
          )}
          onClick={() => !readOnly && onChange(option.value)}
        >
          {option.label}
        </button>)}
      </div>
      {error
        ? <p className="mt-2 text-sm text-red-600">
            {error.message}
          </p>
        : null
      }
    </div>
  )
}

export default ButtonGroup
