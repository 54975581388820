import { useState, useEffect, Fragment } from 'react'
import { useFormContext } from 'react-hook-form'

import { Listbox, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { ClockIcon } from '@heroicons/react/24/outline'

import { twMerge } from 'tailwind-merge'

type DedicatedSelectProps = {
  value: number
  onChange: (value: number) => void
  options: number[]
}

const DedicatedSelect = ({ value, onChange, options }: DedicatedSelectProps) => {
  return <Listbox value={value} onChange={onChange}>
    {({ open }) => (
      <div className="relative">
        <Listbox.Button className="relative w-full p-1 text-center border-none ring-0 outline-none sm:text-sm">
          {`${value < 10 ? '0' : ''}${value}`}
        </Listbox.Button>

        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="no-scrollbar absolute left-5 -top-8 z-10 max-h-48 w-12 overflow-auto bg-white py-1 text-base shadow-2xl border ring-1 ring-primary ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((value) => (
              <Listbox.Option
                key={`hours-${value}`}
                className={({ active }) =>
                  twMerge(
                    active ? 'text-primary' : 'text-gray-900',
                    'relative cursor-pointer select-none py-2 px-2 text-center'
                  )
                }
                value={value}
              >
                {({ selected }) => <span className={twMerge('block truncate', selected ? 'text-primary font-medium' : '')}>
                  {`${value < 10 ? '0' : ''}${value}`}
                </span>}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
}

type TimeProps = {
  id: string
  label?: string
  placeholder?: string
  canReset?: boolean
  readOnly?: boolean
  required?: boolean
  className?: string
  hoursStep?: number
  minutesStep?: number
}

const Time = ({ id, label, placeholder, canReset, readOnly, required, className, hoursStep = 1, minutesStep = 1 }: TimeProps) => {
  const { setValue, watch, formState: { errors } } = useFormContext()

  const [hours, setHours] = useState<number>(Math.floor(watch(id) / 60) || 0)
  const [minutes, setMinutes] = useState<number>(watch(id) % 60 || 0)

  useEffect(() => {
    const watchIdValue = watch(id)
    const inputValue = hours * 60 + minutes
    if (watchIdValue !== inputValue) {
      setValue(id, inputValue)
    }
  }, [hours, minutes])

  useEffect(() => {
    const watchIdValue = watch(id)
    if (watchIdValue) {
      const newHours = Math.floor(watchIdValue / 60)
      const newMinutes = watchIdValue % 60
      if (newHours !== hours) {
        setHours(newHours)
      }
      if (newMinutes !== minutes) {
        setMinutes(newMinutes)
      }
    }
  }, [watch(id)])

  return (
    <div className={twMerge('w-full relative', className)}>
      {label
        ? <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1 pl-1">
            <span>{label}</span>
            {required ? <span className='ml-1'>*</span> : null}
          </label>
        : null
      }
      <div className={twMerge('relative w-full h-10 flex items-center justify-between px-3 rounded-md border border-gray-300 bg-white text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm', readOnly ? 'border-0 ring-0 shadow-none' : '')}>
        <div className='flex items-center'>
          <DedicatedSelect value={hours} onChange={v => setHours(v)} options={Array.from(Array(24).keys()).filter(v => v % hoursStep === 0)} />
          <span>:</span>
          <DedicatedSelect value={minutes} onChange={v => setMinutes(v)} options={Array.from(Array(60).keys()).filter(v => v % minutesStep === 0)} />
        </div>

        {canReset && watch(id)
          ? <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer" onClick={(e) => {
            e.stopPropagation()
            setValue(id, undefined)
          }}>
              <XCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
            </div>
          : null
        }

        <div className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-dark rounded-md ring-offset-2">
          <ClockIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
        </div>

      </div>

      {(errors as any)?.[id]
        ? <p className="mt-1 mx-2 text-xs text-red-600" id={`${id}-error`}>
            {(errors as any)?.[id]?.message}
          </p>
        : null
      }
    </div>
  )
}

export default Time
