import { StrictMode, Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { LazyMotion, domMax } from 'framer-motion'

import './i18n'

import reportWebVitals from './reportWebVitals'

import { LoadingScreen } from 'components'
import ContextsProvider from 'contexts'

import 'react-tooltip/dist/react-tooltip.css'
import './assets/styles/index.css'

import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import axios from 'axios'

if (process.env.REACT_APP_GA4_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA4_ID)
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

axios.interceptors.response.use((res) => res, (err) => {
  if (err?.response?.data?.code === 'auth/id-token-expired') {
    location.reload()
  }
  return err
})

const LazyApp = lazy(() => import('./App'))

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <StrictMode>
    <BrowserRouter>
      <ContextsProvider>
        <HelmetProvider>
          <LazyMotion strict features={domMax}>
            <Suspense fallback={<LoadingScreen />}>
              <LazyApp />
            </Suspense>
          </LazyMotion>
        </HelmetProvider>
      </ContextsProvider>
    </BrowserRouter>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
