import { ElementType, useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { twMerge } from 'tailwind-merge'

type IconButtonProps = {
  Icon: ElementType
  className?: string
  to?: string
  external?: boolean
  onClick?: () => void
  hoverRotate?: boolean
  tooltip?: string
}

const IconButton = ({ Icon, className = '', external, to, onClick, hoverRotate, tooltip }: IconButtonProps) => {
  const finalClassName = useMemo(() => twMerge('transition ease-in-out duration-1250 hover:text-primary cursor-pointer outline-primary outline-1 outline-offset-2', hoverRotate ? 'hover:-rotate-12' : '', className), [className, hoverRotate])

  return to
    ? external
      ? <a href={to} target="_blank" rel="noreferrer">
          <Icon className={finalClassName} aria-hidden="true" data-tooltip-id={tooltip ? 'tooltip' : undefined} data-tooltip-content={tooltip} data-tooltip-place="bottom" />
        </a>
      : <NavLink to={to}>
          <Icon className={finalClassName} aria-hidden="true" data-tooltip-id={tooltip ? 'tooltip' : undefined} data-tooltip-content={tooltip} data-tooltip-place="bottom" />
        </NavLink>
    : <Icon className={finalClassName} onClick={onClick} aria-hidden="true" data-tooltip-id={tooltip ? 'tooltip' : undefined} data-tooltip-content={tooltip} data-tooltip-place="bottom" />
}

export default IconButton
