import { useFormContext } from 'react-hook-form'

import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

type CheckboxProps = {
  id: string
  label?: string
  help?: string
}

const Input = ({ id, label, help }: CheckboxProps) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <>
      <div className="relative flex items-center h-full min-h-[2.25rem]">
        <input
          {...register(id)}
          type='checkbox'
          className='h-4 w-4 rounded border-primary-300 text-primary focus:ring-primary-light cursor-pointer'
        />
        {label
          ? <label htmlFor={id} className="ml-2 text-sm font-medium text-gray-700 flex items-center">
              <span>{label}</span>
              {help
                ? <span data-tooltip-id="tooltip" data-tooltip-content={help} data-tooltip-place='bottom' className='ml-1'><InformationCircleIcon className='h-4 w-4' /></span>
                : null
              }
            </label>
          : null
        }
        {(errors as any)?.[id]
          ? <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          : null
        }
      </div>

      {(errors as any)?.[id]
        ? <p className="mt-1 mx-2 text-xs text-red-600" id={`${id}-error`}>
            {(errors as any)?.[id]?.message}
          </p>
        : null
      }
    </>
  )
}

export default Input
