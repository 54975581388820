import { LockClosedIcon } from '@heroicons/react/24/solid'

import { useNavigate } from 'react-router-dom'

import { useUser } from 'contexts'
import { useTranslation } from 'react-i18next'

type LockProps = {
  children: JSX.Element
  active?: boolean
}

const Lock = ({ children, active }: LockProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeCompany } = useUser()

  return active
    ? <div
        className='relative cursor-pointer'
        data-tooltip-id="tooltip"
        data-tooltip-place="top"
        data-tooltip-content={activeCompany?.role === 'ADMIN' ? t('global:needToUpgrade')! : t('global:askAdminToUpgrade')!}
        onClick={activeCompany?.role === 'ADMIN' ? () => navigate('/offers') : () => {}}
      >
        {children}
        {active
          ? <div className='absolute top-0 left-0 w-full h-full bg-gray-400 bg-opacity-50 rounded-md'>
              <LockClosedIcon className='absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-6 w-6 text-amber-400' />
            </div>
          : null
        }
      </div>
    : children
}

export default Lock
