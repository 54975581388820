import { useFormContext, Controller } from 'react-hook-form'

import ButtonGroup from '../buttons/group'

import type { Option } from 'types'

import { twMerge } from 'tailwind-merge'

type ButtonGroupInputFormProps = {
  id: string
  options: Option[]
  className?: string
}

const ButtonGroupInputForm = ({ id, options, className }: ButtonGroupInputFormProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={id}
      render={({
        field: { onChange, value },
        fieldState: { error }
      }) => <ButtonGroup value={value} onChange={onChange} error={error} options={options} className={className} />
      }
    />
  )
}

type ButtonGroupInputProps = {
  id?: string
  readOnly?: boolean
  options: Option[]
  value?: string
  setValue?: (val: string) => void
  className?: string
}

const ButtonGroupInput = ({ id, readOnly, options, value, setValue, className }: ButtonGroupInputProps) => {
  return readOnly
    ? <div className={twMerge('h-10 flex items-center pl-3', className)}>{value}</div>
    : value && setValue
      ? <ButtonGroup value={value} onChange={setValue} readOnly={readOnly} options={options} className={className} />
      : id
        ? <ButtonGroupInputForm id={id} options={options} className={className} />
        : null
}

export default ButtonGroupInput
