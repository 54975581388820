import { PlanTypes, Offer } from 'types'

const offers: Offer[] = [
  {
    id: 'BASIC' as PlanTypes,
    name: 'Basic',
    favorite: false,
    price: 0,
    vat: false,
    admin: 1,
    agents: 2,
    manager: 1,
    features: {
      dashboard: false,
      invoicing: false,
      geolocation: false,
      signature: false,
      recurring: false
    }
  },
  {
    id: 'PRO' as PlanTypes,
    name: 'Pro',
    favorite: true,
    price: 8,
    vat: true,
    admin: 2,
    agents: 50,
    manager: 5,
    features: {
      dashboard: true,
      invoicing: false,
      geolocation: true,
      signature: true,
      recurring: true
    }
  },
  {
    id: 'ENTERPRISE' as PlanTypes,
    name: 'Entreprise',
    favorite: false,
    price: -1,
    vat: false,
    admin: '∞',
    agents: '∞',
    manager: '∞',
    features: {
      dashboard: true,
      invoicing: true,
      geolocation: true,
      signature: true,
      recurring: true
    }
  }
]

export { offers }
