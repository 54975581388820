import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type CardProps = {
  children: ReactNode
  className?: string
}

const Card = ({ children, className }: CardProps) => {
  return <div className={twMerge('rounded-md px-3 py-2 bg-custom-white shadow-md overflow-hidden', className)}>
    {children}
  </div>
}

export default Card
