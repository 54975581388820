import { ReactNode } from 'react'

import { useFirebase, FirebaseProvider } from './firebase'
import { useUser, UserProvider } from './user'
import { useNotifications, NotificationsProvider } from './notifications'

export { useFirebase, useUser, useNotifications }

type ContextsProviderProps = {
  children: ReactNode
}

const ContextsProvider = ({ children } : ContextsProviderProps) => {
  return (
    <NotificationsProvider>
      <FirebaseProvider>
        <UserProvider>
          {children}
        </UserProvider>
      </FirebaseProvider>
    </NotificationsProvider>
  )
}

export default ContextsProvider
