import { useFormContext } from 'react-hook-form'

import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

import { offers } from 'utils/datas/offers'
import Features from 'components/features'
import { displayPrice } from 'utils/transformers'

type OfferProps = {
  id: string
  label?: boolean
}

const Offer = ({ id, label }: OfferProps) => {
  const { t } = useTranslation()
  const { register, setValue, watch } = useFormContext()

  return (
    <div className="w-full relative">
      {label
        ? <label className="block text-sm font-medium text-gray-700 mb-1 pl-1">
            <span>{t('inputs:offer.label')!}</span>
          </label>
        : null
      }
      <div className='flex flex-row gap-2 flex-wrap'>
        {offers
          .filter(offer => offer.id !== 'ENTERPRISE')
          .map(offer => <div
            key={offer.id}
            className={twMerge('relative flex flex-1 border rounded-md py-2 pl-4 pr-6 hover:cursor-pointer select-none min-w-fit', watch(id) === offer.id ? 'border-primary-trans bg-primary-utrans' : 'border-gray-300 bg-white')}
            onClick={() => setValue(id, offer.id)}
          >
            <input {...register(id)} className="text-primary h-4 w-4 absolute right-2 top-2 border-gray-400" type='radio' value={offer.id} readOnly id={`${id}-${offer.id}`} />
            <div className="flex flex-col">
              <div className="flex gap-2 items-end">
                <span className="font-semibold text-xl text-primary">{t(`offers:name.${offer.id}`)}</span>
                {offer.price === 0
                  ? <div>{t('offers:free')}</div>
                  : <div>{displayPrice(offer.price)} <span className="text-xs">{t('offers:quantity')}</span></div>
                }
              </div>
              <Features offer={offer} className='gap-0' />
            </div>
          </div>)
        }
      </div>
    </div>
  )
}

export default Offer
