import { useUser } from 'contexts'
import { isYesterday, isTomorrow, isToday, format, isBefore, isSameHour } from 'date-fns'
import { Currencies, Intervention, InterventionStatus, Languages } from 'types'
import { getCurrency } from './datas/countries'

export const rolesToBgColor = {
  ADMIN: 'bg-red-100',
  MANAGER: 'bg-blue-100',
  AGENT: 'bg-yellow-100'
}

export const userStatusToBgColor = {
  ARCHIVED: 'bg-red-100',
  INVITED: 'bg-blue-100',
  ACTIVE: 'bg-green-100'
}

export const rolesToValue = {
  ADMIN: 3,
  MANAGER: 2,
  AGENT: 1
}

// eslint-disable-next-line no-unused-vars
export const statusToBadgeBgColor: { [key in InterventionStatus]: string} = {
  [InterventionStatus.PLANNED]: 'bg-blue-500',
  [InterventionStatus.STARTED]: 'bg-green-500',
  [InterventionStatus.FINISHED]: 'bg-gray-500'
}

// eslint-disable-next-line no-unused-vars
export const statusToBgColor: { [key in InterventionStatus]: string} = {
  [InterventionStatus.PLANNED]: 'bg-custom-white',
  [InterventionStatus.STARTED]: 'bg-primary-trans',
  [InterventionStatus.FINISHED]: 'bg-green-200'
}

export const dateToRelativeDate = (date: Date, t: (key: string, params?: Record<string, any> | undefined) => string, defaultFormat?: string) => {
  return isYesterday(date) ? t('datas:dates.yesterday') : isTomorrow(date) ? t('datas:dates.tomorrow') : isToday(date) ? t('datas:dates.today') : format(date, defaultFormat || 'EEE d MMM')
}

type DisplayPricePtions = {
  digit?: number
  currency?: Currencies
  language?: Languages
}

export const displayPrice = (price: number, options?: DisplayPricePtions) => {
  const { user, activeCompany } = useUser()

  return Intl.NumberFormat(options?.language || user?.language || 'en', {
    style: 'currency',
    currency: options?.currency || (activeCompany ? getCurrency(activeCompany.country) : 'USD'),
    minimumFractionDigits: options?.digit || 0,
    maximumFractionDigits: options?.digit || 0
  }).format(price)
}

export const sortInterventions = (i1: Intervention, i2: Intervention) =>
  InterventionStatus[i1.status] < InterventionStatus[i2.status]
    ? 1
    : InterventionStatus[i1.status] > InterventionStatus[i2.status]
      ? -1
      : isBefore(new Date(i1.beginAt!), new Date(i2.beginAt!))
        ? -1
        : !isSameHour(new Date(i1.beginAt!), new Date(i2.beginAt!))
            ? 1
            : isBefore(new Date(i1.endAt!), new Date(i2.endAt!))
              ? -1
              : 1
