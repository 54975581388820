import { ReactElement, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

import { Search } from 'components/inputs'

import Pagination from './pagination'

import { twMerge } from 'tailwind-merge'

type Column = {
  id: string
  name: string
  sortId?: string
  display: (data: any, loading?: boolean) => ReactElement
}

type TableProps = {
  columns: Column[]
  actionColumns?: Column[]
  datas: any[]
  countData?: number
  sortBy?: string
  sortType?: 'asc' | 'desc'
  updateSort?: (id: string) => void
  page?: number
  setPage?: Dispatch<SetStateAction<number>>
  perPage?: number
  setPerPage?: Dispatch<SetStateAction<number>>
  search?: string
  setSearch?: Dispatch<SetStateAction<string>>
  loading: boolean
  skeletonRow?: number
  noDataOptions?: {
    title: string
    illustration: string
    action?: {
      label: string
      onClick: () => void
    }
  }
}

const Table2 = ({ columns, actionColumns, datas, countData, sortBy, sortType, updateSort = () => {}, page, setPage, perPage, setPerPage, search, setSearch, loading, skeletonRow = 10, noDataOptions }: TableProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          {setSearch ? <Search value={search!} setValue={setSearch} placeholder={t('inputs:search.placeholder')!} className='max-w-xs mb-2' /> : null}
          <table className="min-w-full divide-y divide-gray-300 table-fixed">
            <thead>
              <tr>
                {columns.map((column: Column) => (
                  <th key={column.id} scope="col" className={twMerge('select-none whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6 md:pl-0', column.sortId ? 'cursor-pointer' : '')} onClick={column.sortId ? () => updateSort(column.sortId!) : undefined}>
                    <div className="flex">
                      <span>{column.name}</span>
                      <span className='w-4 h-4'>{column.sortId && sortBy === column.sortId ? sortType === 'asc' ? <ChevronDownIcon className="h-4 w-4 ml-1 inline-flex" /> : <ChevronUpIcon className="h-4 w-4 ml-1 inline-flex" /> : null}</span>
                    </div>
                  </th>
                ))}
                {actionColumns?.map((column: Column) => (
                  <th key={column.id} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {loading
                ? Array(skeletonRow).fill(0).map((e, i) => <tr key={`skeleton-row-${i}`}>
                    {columns.map(column => (
                      <td key={`${column.id}-${i}-loading`} className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6 md:pl-0">
                        {column.display({}, true)}
                      </td>
                    ))}
                    {actionColumns?.map((column: Column) => (
                      <td key={`${column.id}-${i}-action-loading`} className="whitespace-nowrap py-3.5 pl-4 pr-3 text-right text-sm text-gray-900 sm:pl-6 md:pl-0">
                        {column.display({}, true)}
                      </td>
                    ))}
                  </tr>)
                : !datas.length
                    ? <tr><td colSpan={columns.length + (actionColumns?.length || 0)} className="text-center pt-4 text-lg">{t('global:noDataMatching')}</td></tr>
                    : datas.map((data: any) => (
                        <tr key={data.id}>
                          {columns.map((column: Column) => (
                            <td key={`${column.id}-${data.id}`} className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6 md:pl-0">
                              {column.display(data)}
                            </td>
                          ))}
                          {actionColumns?.map((column: Column) => (
                            <td key={`${column.id}-${data.id}`} className="whitespace-nowrap py-3.5 pl-4 pr-3 text-right text-sm text-gray-900 sm:pl-6 md:pl-0">
                              {column.display(data)}
                            </td>
                          ))}
                          </tr>
                    ))
              }
            </tbody>
          </table>
          {countData && perPage && page && setPage && setPerPage ? <Pagination countData={countData} perPage={perPage} page={page} setPage={setPage} setPerPage={setPerPage} className='border-t border-gray-300' /> : null}
        </div>
      </div>
    </div>
  )
}

export default Table2
