import { ReactElement, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { ChevronLeftIcon } from '@heroicons/react/24/outline'

import { HeroIcon } from 'types'

import { IconButton, Button } from 'components/buttons'
import { useTranslation } from 'react-i18next'

type Tab = {
  id: string
  name: string
}

type Tabs = {
  tabs: Tab[]
  currentTab: string
  setCurrentTab: (id: string) => void
}

type ActionButton = {
  name: string
  onClick?: () => void
  to?: string
  icon?: HeroIcon
  outlined?: boolean
}

type HeaderProps = {
  title: string | ReactNode
  subtitle?: string | ReactNode
  back?: string
  actions?: ActionButton[]
  tabs?: Tabs
  actionsElement?: ReactElement
}

const Header = ({ title, subtitle, back, actions, actionsElement, tabs }: HeaderProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={twMerge('flex flex-col relative mb-3', tabs ? ' border-b border-gray-200' : '')}>
      <div className="flex items-center justify-between mb-3">
        <div className="flex flex-col gap-1">
          <div className="flex">
            {back ? <IconButton Icon={ChevronLeftIcon} className="h-6 w-6 mr-3" to={back} tooltip={t('global:actions.back')!} /> : null}
            <h3 className="text-2xl font-medium leading-6 text-gray-900">{title}</h3>
          </div>
          {subtitle ? <span className={twMerge('text-xs', back ? 'ml-9' : '')}>{subtitle}</span> : null}
        </div>
        {actionsElement || (actions
          ? <div className="mt-3 flex md:right-0 md:mt-0 gap-3">
              {actions.map(act => (<Button
                  key={act.name}
                  outlined={act.outlined}
                  onClick={act.onClick ? act.onClick : () => navigate(act.to!)}
                >
                  <div className="flex gap-1 items-center">
                    {act.icon ? <act.icon className="h-4 w-4" aria-hidden="true" /> : null}
                    {act.name}
                  </div>
                </Button>
              ))}
            </div>
          : null)
        }
      </div>
      {tabs
        ? <div>
            <div className="sm:hidden">
              <label htmlFor="current-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="current-tab"
                name="current-tab"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={tabs.currentTab}
              >
                {tabs.tabs.map((tab) => (
                  <option key={tab.id}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8">
                {tabs.tabs.map((tab) => (
                  <span
                    key={tab.id}
                    onClick={() => tabs.setCurrentTab(tab.id)}
                    className={twMerge(
                      tab.id === tabs.currentTab
                        ? 'border-primary text-primary-dark'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                    )}
                    aria-current={tab.id === tabs.currentTab ? 'page' : undefined}
                  >
                    {tab.name}
                  </span>
                ))}
              </nav>
            </div>
          </div>
        : null
      }
    </div>
  )
}

export default Header
